import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';

import { OmitStylesButtonHTMLAttributes } from '../../types';
import { cn } from '../../utils/cn';
import { LoaderCircle } from 'lucide-react';

export const buttonVariants = cva(
    'rocco-button group z-1 text-base inline-flex items-center justify-center whitespace-nowrap ring-offset-background overflow-hidden border border-transparent focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 font-normal cursor-pointer',
    {
        variants: {
            color: {
                primary:
                    'bg-brick-50 text-primary-foreground border-brick-50 hover:bg-brick-200 hover:border-brick-200 hover:text-primary-foreground',
                secondary:
                    'bg-gray-100 text-secondary-foreground border-gray-100 hover:bg-gray-200 hover:border-gray-100',
                destructive: 'bg-destructive text-destructive-foreground',
                muted: 'bg-gray-900 text-gray-50',
                signUp: 'bg-gray-100 text-secondary-foreground hover:bg-gray-200 min-w-[8.125rem]',
                transparent: 'bg-transparent text-gray-900 fill-gray-900',
                white: 'bg-white text-black fill-black border-white hover:bg-gray-50/70 hover:border-gray-50/70',
                dark: 'bg-black text-white fill-white hover:bg-gray-700',
                gray: 'bg-[#5B5D5E] text-white fill-white hover:bg-gray-700',
                outline:
                    'bg-transparent border text-gray-900 fill-gray-900 border-gray-900',
                outlineBM:
                    'bg-transparent border text-gray-900 fill-gray-900 border-gray-900 hover:bg-black hover:text-white hover:fill-white [&_svg]:hover:text-white',
                glassy: 'tracking-[0.02rem] border text-gray-900 fill-gray-900 bg-[rgba(20,20,20,0.18)] backdrop-blur-island md:hover:bg-gray-900 md:hover:text-white md:hover:fill-white',
                secondary70:
                    'bg-gray-50/70 text-secondary-foreground  hover:border-gray-50 hover:bg-gray-50 backdrop-blur-island',
            },
            inverted: {
                true: '',
                false: '',
            },
            size: {
                default: 'px-6 py-2', //  42px
                larger: 'px-8 py-2.5', // 46.8px
                xLarge: 'px-8 py-4.5', // 58px
                badge: 'px-4 py-2',
                narrow: 'px-3 py-1',
                compact: 'px-0 py-2',
                square18: 'size-4.5',
                square24: 'size-6',
                square36: 'size-[2.25rem]', // 36px
                square40: 'size-[2.5rem]', // 40px
                square42: 'size-[2.625rem]', // 42px
                square44: 'size-11', // 44px
                square50: 'size-[3.125rem]', // 50px
                square54: 'size-[3.375rem]', // 54px
                square64: 'size-[4rem]',
                square72: 'size-18',
            },
            shape: {
                default: 'rounded-full',
                squareRounded: 'rounded-2',
                square: 'rounded-none',
            },
            border: {
                default: '',
                white: 'border border-white',
                dark: 'border border-gray-900',
            },
            layoutMode: {
                default: '',
                fillFlex: 'flex-1',
                noShrink: 'shrink-0',
            },
            minWidth: {
                default: '',
                '200': 'min-w-[12.5rem]',
                '170': 'min-w-[10.625rem]',
                '180': 'min-w-[11.25rem]',
            },
            weight: {
                default: 'font-medium',
                normal: 'font-normal',
            },
            overflow: {
                default: '',
                visible: 'overflow-visible',
            },
        },
        compoundVariants: [
            {
                color: 'primary',
                inverted: true,
                class: 'bg-primary-foreground text-brick-50 border-primary-foreground',
            },
            {
                color: 'secondary',
                inverted: true,
                class: 'bg-gray-900 text-gray-100 border-gray-900 hover:bg-gray-700 hover:border-gray-700',
            },
            {
                color: 'destructive',
                inverted: true,
                class: 'bg-destructive-foreground text-destructive',
            },
            {
                color: 'muted',
                inverted: true,
                class: 'bg-gray-50 text-gray-900',
            },
            {
                color: 'signUp',
                inverted: true,
                class: 'bg-white/80 text-black fill-black hover:bg-white/60',
            },
            {
                color: 'transparent',
                inverted: true,
                class: 'text-white fill-white',
            },
            {
                color: 'outline',
                inverted: true,
                class: 'text-white fill-white border-white',
            },
            {
                color: 'glassy',
                inverted: true,
                class: 'text-white fill-white',
            },
        ],
        defaultVariants: {
            color: 'primary',
            size: 'default',
            shape: 'default',
            inverted: false,
            layoutMode: 'default',
            minWidth: 'default',
            weight: 'default',
            overflow: 'default',
        },
    },
);

export interface ButtonProps
    extends OmitStylesButtonHTMLAttributes<HTMLButtonElement>,
        VariantProps<typeof buttonVariants> {
    className?: string;
    asChild?: boolean;
    isLoading?: boolean;
    clickAnimation?: 'scale' | false;
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
    (props, ref) => {
        const {
            children,
            color,
            size,
            shape,
            inverted,
            layoutMode,
            className,
            asChild = false,
            isLoading,
            minWidth,
            weight,
            overflow,
            clickAnimation = false,
            ...rest
        } = props;

        const Comp = asChild ? Slot : 'button';
        return (
            <Comp
                className={cn(
                    buttonVariants({
                        color,
                        size,
                        shape,
                        inverted,
                        layoutMode,
                        minWidth,
                        weight,
                        overflow,
                    }),
                    clickAnimation === 'scale' &&
                        'active:scale-95 transition-transform duration-100 transform-gpu origin-center',
                    className,
                )}
                ref={ref}
                {...rest}
            >
                {isLoading !== undefined ? (
                    <div className="relative grid grid-stack justify-items-center items-center">
                        <LoaderCircle
                            className={cn(
                                'animate-spin',
                                children && 'absolute',
                                isLoading ? 'visible' : 'invisible',
                            )}
                        />
                        <div
                            className={cn(
                                'flex items-center justify-center',
                                isLoading ? 'invisible' : 'visible',
                            )}
                        >
                            {children}
                        </div>
                    </div>
                ) : (
                    children
                )}
            </Comp>
        );
    },
);

Button.displayName = 'Button';
